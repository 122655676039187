
.goods-shoot-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .goods-shoot-header {
        display: flex;
        justify-content: space-between;
        /*align-items: center;*/
        /*height: 58px;*/
        border-bottom: 2px solid #F1F5FF;
        span {
            color: #333;
            font-size: 16px;
        }
    }
}
.first-title {
    font-size: 20px;
    padding-left: 15px;
    line-height: 1;
    margin-top: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 5px;
        height: 20px;
        background: #2DC079;
        border-radius: 3px;
        transform: translateY(-50%);
    }
}
.goods-images-box {
    background: #F6F6F6;
    margin-top: 14px;
    padding: 20px 15px;
}
.column-layout {
    display: flex;
    flex-direction: column;
    ::v-deep {
        .el-form-item__label {
            text-align: left;
            line-height: 1;
            margin-bottom: 20px;
            width: auto!important;
        }
        .el-form-item__content {
            margin-left: 0!important;
        }
    }
}
.good-image-item + .good-image-item {
    margin-left: 10px;
}
.good-image-item {
    width: 100px;
    height: 100px;
    position: relative;
    background: #fff;
    .clear-quickTalk-img {
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: -9px;
        right: -9px;
        z-index: 1;
        font-size: 18px;
        color: #aaa;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            color: #ff0000;
        }
    }
    .img-uploader {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .quickTalk-img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .add-icon {
                color: #BFBFBF;
                font-size: 24px;
            }
            .add-text {
                color: #999;
                font-size: 14px;
                margin-top: 8px;
            }
        }
    }
}
